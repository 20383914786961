var countUpStats = true;
var countUpStatsDuration = 3000;

// Inject quick exit button
$('body').append('<a id="quick-exit-button" class="button">Quick exit</a>');
$('#quick-exit-button').on('click', function() {
  window.location.replace('https://www.bbc.co.uk');
});

// Feed titles as links
$('.homeFeednews .feedsTitle').wrapInner('<a href="/Pages/News/Default.aspx" />');
$('.homeFeedevents .feedsTitle').wrapInner('<a href="/Pages/Events/Default.aspx" />');

// Lift up the announcement alert footer box snippet
$('.footerBox.AnnouncementAlert').appendTo('.pageHeader');



// Page - Logo container
$('body.PostCategory_page-logo-slider .postContent a')
  .filter(':has(.mediaImage)')
  .wrapAll('<div class="logoContainer" />');

$('body.PostCategory_page-logo-slider .postContent .logoContainer').slick ({
  slidesToShow: 5,
  centerMode: false,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  pauseOnFocus: false,
  pauseOnHover: false,
  pauseOnDotsHover: false,
  slickPause: false,
  autoplay: true,
  autoplaySpeed: 4000,
  draggable: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
});

// Homepage logos
$(".Footer-Logos").insertAfter(".homeFeedsWrapper ");